<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'

const props = defineProps({
  modelValue: { type: Number, default: 0 },
  show: { type: Boolean, required: true },
  appear: { type: Boolean, default: false },
  duration: { type: Number, default: 300 },
  name: { type: String, default: 'reveal-y' }
})

const emit = defineEmits(['update:modelValue'])

const scrollHeight = ref(0)

const observer = ref<ResizeObserver | null>(null)

const element = ref<HTMLElement | null>(null)

onMounted(() => {
  observer.value = new ResizeObserver((entries) => {
    for (const entry of entries) {
      entry.target.style.setProperty('--scroll-height', `${entry.target.scrollHeight}px`)
      scrollHeight.value = entry.target.scrollHeight
    }
  })
})

onUnmounted(() => {
  observer.value?.disconnect()
})

watch(() => [observer.value, element.value], ([observer, element]) => {
  if (observer && element) {
    observer.observe(element)
    element.style.setProperty('--scroll-height', `${element.scrollHeight}px`)
    element.addEventListener('transitionend', (ev) => {
      if (ev.target !== element) return
      animating.value = false
      classList.value.clear()
      if (props.show) {
        classList.value.add('showing')
        classList.value.add('-leave-from')
      } else {
        classList.value.add('hiding')
        classList.value.add('-enter-from')
      }
    })
  }
}, { immediate: true })

const animating = ref(false)
const classList = ref(new Set<string>(['learning']))

watch(() => [!!scrollHeight.value, props.show], ([hasHeight, show], [prevHasHeight, prevShow]) => {
  if (hasHeight && !prevHasHeight) {
    classList.value.clear()
    if (show) {
      classList.value.add('showing')
      classList.value.add('-enter-to')
      if (props.appear) {
        classList.value.add('-enter-active')
      }
    } else {
      classList.value.add('hiding')
      classList.value.add('-enter-from')
    }
  }
  if (hasHeight && show && !prevShow) {
    animating.value = true
    classList.value.clear()
    classList.value.add('-enter-active')
    classList.value.add('-enter-to')
  } else if (hasHeight && !show && prevShow) {
    animating.value = true
    classList.value.clear()
    classList.value.add('-leave-active')
    classList.value.add('-leave-to')
  }

  if (show) {
    emit('update:modelValue', scrollHeight.value)
  } else {
    emit('update:modelValue', 0)
  }
})

const classObj = computed(() => Object.fromEntries([...classList.value]
  .map(c => [
    c.startsWith('-')
      ? `${props.name}${c}`
      : c,
    true
  ])
))

const styleObj = computed(() => ({
  '--transition-duration': `${props.duration}ms`
}))

</script>

<template>
  <div
    ref="element"
    class="RevealingContent"
    :class="classObj"
    :style="styleObj"
  >
    <slot />
  </div>
</template>

<style lang="scss" scoped>
  .learning {
    max-height: 0;
    overflow: hidden;
  }
  .hiding {
    visibility: hidden;
  }
</style>
